import React from 'react';

const CentimetresIcon = () => {
    return (
      <svg width="100%" height="100%" viewBox="0 0 121 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.93934 49.9393C0.353553 50.5251 0.353553 51.4749 0.93934 52.0607L10.4853 61.6066C11.0711 62.1924 12.0208 62.1924 12.6066 61.6066C13.1924 61.0208 13.1924 60.0711 12.6066 59.4853L4.12132 51L12.6066 42.5147C13.1924 41.9289 13.1924 40.9792 12.6066 40.3934C12.0208 39.8076 11.0711 39.8076 10.4853 40.3934L0.93934 49.9393ZM120.061 52.0607C120.646 51.4749 120.646 50.5251 120.061 49.9394L110.515 40.3934C109.929 39.8076 108.979 39.8076 108.393 40.3934C107.808 40.9792 107.808 41.9289 108.393 42.5147L116.879 51L108.393 59.4853C107.808 60.0711 107.808 61.0208 108.393 61.6066C108.979 62.1924 109.929 62.1924 110.515 61.6066L120.061 52.0607ZM2 52.5L119 52.5L119 49.5L2 49.5L2 52.5Z" fill="#BC8762"/>
        <path d="M27.7822 40H32.1621V18.8623H27.7676L22.3037 22.6562V26.582L27.6943 22.9199H27.7822V40ZM45.0234 40.3662C49.8574 40.3662 53.1973 37.3633 53.1973 32.9395V32.9102C53.1973 28.9258 50.2969 26.04 46.2832 26.04C44.1445 26.04 42.4014 26.9043 41.5225 28.3252H41.4346L41.9473 22.3779H51.9229V18.8623H38.4316L37.4648 31.2695H41.3174C41.5664 30.8301 41.9033 30.4199 42.3281 30.0977C43.0459 29.5264 43.9688 29.2041 45.0527 29.2041C47.3379 29.2041 48.9639 30.7715 48.9785 32.998V33.0273C48.9785 35.2832 47.3525 36.8652 45.0381 36.8652C43.002 36.8652 41.5225 35.6348 41.2148 34.0381L41.1855 33.9355H37.0986L37.1133 34.1553C37.377 37.6855 40.4238 40.3662 45.0234 40.3662ZM68.7832 40.3223C72.9873 40.3223 75.6826 37.7148 75.8877 34.2871L75.9023 34.1992H71.918L71.8887 34.3311C71.6104 35.9131 70.541 36.9678 68.8271 36.9678C66.7031 36.9678 65.3848 35.2246 65.3848 32.1484V32.1338C65.3848 29.1309 66.7031 27.3291 68.8125 27.3291C70.5703 27.3291 71.625 28.4717 71.8887 29.9658L71.9033 30.0977H75.8877L75.873 29.9951C75.7119 26.5967 73.002 23.9746 68.7539 23.9746C64.0371 23.9746 61.0342 27.0947 61.0342 32.1191V32.1338C61.0342 37.2021 64.0078 40.3223 68.7832 40.3223ZM82.2598 40V29.4971H82.3623L86.4053 39.1211H89.3643L93.4219 29.4971H93.5244V40H97.5527V24.2969H92.0596L87.9727 34.5508H87.8701L83.7832 24.2969H78.2314V40H82.2598Z" fill="#BC8762"/>
      </svg>

    );
};

export default CentimetresIcon;
