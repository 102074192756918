import React from 'react';

const WarrantyIcon = () => {
  return (
    <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.9912 10.853L34.9899 10.8219C34.9725 10.4377 34.9609 10.0312 34.9539 9.57891C34.9213 7.37341 33.168 5.54907 30.9622 5.42578C26.3633 5.16913 22.8055 3.6695 19.7657 0.706556L19.7397 0.681837C18.7482 -0.227279 17.2526 -0.227279 16.2607 0.681837L16.2348 0.706556C13.195 3.6695 9.63723 5.16913 5.03824 5.42609C2.83274 5.54907 1.07921 7.37341 1.04656 9.57921C1.03984 10.0284 1.02794 10.4349 1.01054 10.8219L1.00871 10.8942C0.919297 15.5838 0.808214 21.4203 2.76072 26.7178C3.83433 29.631 5.4603 32.1634 7.59317 34.245C10.0224 36.6156 13.2041 38.4976 17.0499 39.8385C17.175 39.8822 17.3051 39.9176 17.4375 39.9441C17.6243 39.9813 17.8123 40 18.0002 40C18.1882 40 18.3765 39.9813 18.563 39.9441C18.6954 39.9176 18.8264 39.8819 18.9521 39.8379C22.7933 38.4945 25.9717 36.6116 28.3988 34.2413C30.5307 32.1591 32.1567 29.6262 33.2312 26.7123C35.1911 21.3989 35.0803 15.5515 34.9912 10.853ZM31.0291 25.9003C28.9698 31.4838 24.766 35.3183 18.1775 37.6223C18.1541 37.6303 18.129 37.6373 18.1031 37.6425C18.035 37.6559 17.9658 37.6559 17.8968 37.6422C17.8712 37.637 17.8461 37.6303 17.8229 37.6223C11.2275 35.3225 7.02096 31.4902 4.96317 25.9064C3.16355 21.0233 3.26548 15.6656 3.35551 10.9391L3.35612 10.9128C3.37443 10.5066 3.38664 10.0812 3.39335 9.6137C3.408 8.63439 4.18803 7.82415 5.16916 7.76953C7.77169 7.62426 10.0581 7.12133 12.1589 6.23205C14.257 5.34399 16.1204 4.09217 17.855 2.40516C17.9432 2.3307 18.0576 2.33039 18.1455 2.40516C19.8804 4.09217 21.7438 5.34399 23.8416 6.23205C25.9424 7.12133 28.2288 7.62426 30.8316 7.76953C31.8128 7.82415 32.5928 8.63439 32.6071 9.614C32.6142 10.084 32.6264 10.5094 32.6447 10.9128C32.7344 15.6449 32.8339 21.0068 31.0291 25.9003Z" fill="#BC8762"/>
      <path d="M17.9998 10.0322C12.503 10.0322 8.03125 14.5043 8.03125 20.0008C8.03125 25.4976 12.503 29.9697 17.9998 29.9697C23.4963 29.9697 27.9684 25.4976 27.9684 20.0008C27.9684 14.5043 23.4963 10.0322 17.9998 10.0322ZM17.9998 27.6223C13.7972 27.6223 10.3784 24.2034 10.3784 20.0008C10.3784 15.7982 13.7972 12.3793 17.9998 12.3793C22.2021 12.3793 25.621 15.7982 25.621 20.0008C25.621 24.2034 22.2021 27.6223 17.9998 27.6223Z" fill="#BC8762"/>
      <path d="M20.8649 16.906L16.2347 21.5361L14.978 20.2794C14.5196 19.8211 13.7765 19.8211 13.3182 20.2794C12.8601 20.7378 12.8601 21.4809 13.3182 21.939L15.405 24.0258C15.6341 24.255 15.9344 24.3694 16.2347 24.3694C16.535 24.3694 16.8353 24.255 17.0645 24.0258L22.5247 18.5656C22.9828 18.1072 22.9828 17.3641 22.5247 16.906C22.0663 16.4473 21.3232 16.4473 20.8649 16.906Z" fill="#BC8762"/>
    </svg>

  );
};

export default WarrantyIcon;
