import React from 'react';

const CloseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2929 3.13282C23.6834 2.7423 23.6834 2.10913 23.2929 1.71861L22.2814 0.707106C21.8909 0.316582 21.2577 0.316582 20.8672 0.707107L12.7071 8.86718C12.3166 9.2577 11.6834 9.2577 11.2929 8.86718L3.13282 0.707108C2.7423 0.316583 2.10913 0.316583 1.71861 0.707107L0.707106 1.71861C0.316582 2.10913 0.316583 2.7423 0.707107 3.13282L8.86718 11.2929C9.2577 11.6834 9.2577 12.3166 8.86718 12.7071L0.707108 20.8672C0.316583 21.2577 0.316582 21.8909 0.707106 22.2814L1.71861 23.2929C2.10913 23.6834 2.7423 23.6834 3.13282 23.2929L11.2929 15.1328C11.6834 14.7423 12.3166 14.7423 12.7071 15.1328L20.8672 23.2929C21.2577 23.6834 21.8909 23.6834 22.2814 23.2929L23.2929 22.2814C23.6834 21.8909 23.6834 21.2577 23.2929 20.8672L15.1328 12.7071C14.7423 12.3166 14.7423 11.6834 15.1328 11.2929L23.2929 3.13282Z" fill="#828282"/>
        </svg>
    );
};

export default CloseIcon;
