import React from 'react';

const HomeIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 233 233" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M224.991 101.938L203.875 80.8364V29.125C203.875 21.1156 197.322 14.5625 189.312 14.5625H174.75C166.741 14.5625 160.188 21.1156 160.188 29.125V37.1781L131.062 8.08219C127.087 4.32506 123.446 0 116.5 0C109.554 0 105.913 4.32506 101.938 8.08219L8.00938 101.938C3.46588 106.67 0 110.122 0 116.5C0 124.699 6.291 131.062 14.5625 131.062H29.125V218.438C29.125 226.447 35.6781 233 43.6875 233H87.375V160.188C87.375 152.178 93.9281 145.625 101.938 145.625H131.062C139.072 145.625 145.625 152.178 145.625 160.188V233H189.312C197.322 233 203.875 226.447 203.875 218.438V131.062H218.438C226.709 131.062 233 124.699 233 116.5C233 110.122 229.534 106.67 224.991 101.938Z" fill="#F5F5F7"/>
        </svg>

    );
};

export default HomeIcon;
