import React from 'react';

const CloseDillerIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.894291 0.894283C1.04666 0.74191 1.25333 0.656307 1.46882 0.656307C1.6843 0.656307 1.89097 0.74191 2.04334 0.894283L7.78858 6.63953L13.5338 0.894283C13.6862 0.74191 13.8929 0.656308 14.1083 0.656308C14.3238 0.656308 14.5305 0.74191 14.6829 0.894283C14.8352 1.04666 14.9208 1.25332 14.9208 1.46881C14.9208 1.6843 14.8352 1.89096 14.6829 2.04333L8.93763 7.78857L14.6829 13.5338C14.8352 13.6862 14.9208 13.8929 14.9208 14.1083C14.9208 14.3238 14.8352 14.5305 14.6829 14.6829C14.5305 14.8352 14.3238 14.9208 14.1083 14.9208C13.8929 14.9208 13.6862 14.8352 13.5338 14.6829L7.78858 8.93762L2.04334 14.6829C1.89097 14.8352 1.6843 14.9208 1.46882 14.9208C1.25333 14.9208 1.04666 14.8352 0.894291 14.6829C0.741918 14.5305 0.656316 14.3238 0.656316 14.1083C0.656316 13.8929 0.741918 13.6862 0.894291 13.5338L6.63953 7.78857L0.894291 2.04333C0.741918 1.89096 0.656316 1.6843 0.656316 1.46881C0.656316 1.25332 0.741918 1.04666 0.894291 0.894283Z" fill="#BC8762"/>
        </svg>
    );
};

export default CloseDillerIcon;
