import React from 'react';

const CalendarIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.4888 27.8125H5.35448C3.93438 27.8125 2.57245 27.2528 1.56829 26.2565C0.564131 25.2602 0 23.909 0 22.5V7.625C0 6.21604 0.564131 4.86478 1.56829 3.8685C2.57245 2.87221 3.93438 2.3125 5.35448 2.3125H22.4888C23.9089 2.3125 25.2708 2.87221 26.275 3.8685C27.2792 4.86478 27.8433 6.21604 27.8433 7.625V22.5C27.8433 23.909 27.2792 25.2602 26.275 26.2565C25.2708 27.2528 23.9089 27.8125 22.4888 27.8125ZM5.35448 4.4375C4.50242 4.4375 3.68526 4.77332 3.08277 5.3711C2.48027 5.96887 2.14179 6.77962 2.14179 7.625V22.5C2.14179 23.3454 2.48027 24.1561 3.08277 24.7539C3.68526 25.3517 4.50242 25.6875 5.35448 25.6875H22.4888C23.3409 25.6875 24.158 25.3517 24.7605 24.7539C25.363 24.1561 25.7015 23.3454 25.7015 22.5V7.625C25.7015 6.77962 25.363 5.96887 24.7605 5.3711C24.158 4.77332 23.3409 4.4375 22.4888 4.4375H5.35448Z" fill="#BC8762"/>
            <path d="M22.4873 23.5625H18.2037C17.9197 23.5625 17.6473 23.4506 17.4465 23.2513C17.2456 23.052 17.1328 22.7818 17.1328 22.5V18.25C17.1328 17.9682 17.2456 17.698 17.4465 17.4987C17.6473 17.2994 17.9197 17.1875 18.2037 17.1875H22.4873C22.7713 17.1875 23.0437 17.2994 23.2445 17.4987C23.4454 17.698 23.5582 17.9682 23.5582 18.25V22.5C23.5582 22.7818 23.4454 23.052 23.2445 23.2513C23.0437 23.4506 22.7713 23.5625 22.4873 23.5625ZM19.2746 21.4375H21.4164V19.3125H19.2746V21.4375Z" fill="#BC8762"/>
            <path d="M26.7724 10.8125H1.0709C0.786876 10.8125 0.51449 10.7006 0.313658 10.5013C0.112826 10.302 0 10.0318 0 9.75C0 9.46821 0.112826 9.19796 0.313658 8.9987C0.51449 8.79944 0.786876 8.6875 1.0709 8.6875H26.7724C27.0564 8.6875 27.3288 8.79944 27.5296 8.9987C27.7305 9.19796 27.8433 9.46821 27.8433 9.75C27.8433 10.0318 27.7305 10.302 27.5296 10.5013C27.3288 10.7006 27.0564 10.8125 26.7724 10.8125Z" fill="#BC8762"/>
            <path d="M8.56699 6.5625C8.28297 6.5625 8.01058 6.45056 7.80975 6.2513C7.60892 6.05204 7.49609 5.78179 7.49609 5.5V1.25C7.49609 0.968207 7.60892 0.697956 7.80975 0.498699C8.01058 0.299442 8.28297 0.1875 8.56699 0.1875C8.85101 0.1875 9.12339 0.299442 9.32423 0.498699C9.52506 0.697956 9.63788 0.968207 9.63788 1.25V5.5C9.63788 5.78179 9.52506 6.05204 9.32423 6.2513C9.12339 6.45056 8.85101 6.5625 8.56699 6.5625Z" fill="#BC8762"/>
            <path d="M19.2779 6.5625C18.9939 6.5625 18.7215 6.45056 18.5207 6.2513C18.3199 6.05204 18.207 5.78179 18.207 5.5V1.25C18.207 0.968207 18.3199 0.697956 18.5207 0.498699C18.7215 0.299442 18.9939 0.1875 19.2779 0.1875C19.5619 0.1875 19.8343 0.299442 20.0352 0.498699C20.236 0.697956 20.3488 0.968207 20.3488 1.25V5.5C20.3488 5.78179 20.236 6.05204 20.0352 6.2513C19.8343 6.45056 19.5619 6.5625 19.2779 6.5625Z" fill="#BC8762"/>
        </svg>
    );
};

export default CalendarIcon;
