import React from 'react';

const ShowRoomsCallIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.382 10.382C8.6007 12.1633 6.5379 13.8661 5.72272 13.0507C4.55519 11.8834 3.83542 10.8666 1.26209 12.9348C-1.31147 15.0039 0.665159 16.3824 1.79601 17.5137C3.10101 18.8185 7.96575 17.5823 12.7742 12.7746C17.582 7.96575 18.8191 3.10109 17.5132 1.79566C16.3821 0.664602 15.0035 -1.31132 12.9353 1.26242C10.8667 3.83503 11.883 4.55502 13.0508 5.72253C13.8657 6.53792 12.1634 8.60046 10.382 10.382Z" fill="#BC8762"/>
        </svg>
    );
};

export default ShowRoomsCallIcon;
