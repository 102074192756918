import React from 'react';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Succes from "../components/Succes";

const SuccessPage = () => {
    return (
        <>
            <Header/>
            <Succes/>
            <Footer/>
        </>
    );
};

export default SuccessPage;
