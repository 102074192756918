import React from 'react';

const PlusIcon = ({fill = "white"}) => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" opacity='0.5' fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 0.125C9.7072 0.125 9.90592 0.20731 10.0524 0.353823C10.1989 0.500336 10.2812 0.69905 10.2812 0.90625V8.71875H18.0938C18.301 8.71875 18.4997 8.80106 18.6462 8.94757C18.7927 9.09409 18.875 9.2928 18.875 9.5C18.875 9.7072 18.7927 9.90592 18.6462 10.0524C18.4997 10.1989 18.301 10.2812 18.0938 10.2812H10.2812V18.0938C10.2812 18.301 10.1989 18.4997 10.0524 18.6462C9.90592 18.7927 9.7072 18.875 9.5 18.875C9.2928 18.875 9.09409 18.7927 8.94757 18.6462C8.80106 18.4997 8.71875 18.301 8.71875 18.0938V10.2812H0.90625C0.69905 10.2812 0.500336 10.1989 0.353823 10.0524C0.20731 9.90592 0.125 9.7072 0.125 9.5C0.125 9.2928 0.20731 9.09409 0.353823 8.94757C0.500336 8.80106 0.69905 8.71875 0.90625 8.71875H8.71875V0.90625C8.71875 0.69905 8.80106 0.500336 8.94757 0.353823C9.09409 0.20731 9.2928 0.125 9.5 0.125Z" fill={fill}/>
        </svg>
    );
};

export default PlusIcon;
