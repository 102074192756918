import React from 'react';

const BoxIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1052_821)">
                <path d="M27.0456 54.692L27.0449 54.6916L0.709022 40.9416L0.708898 40.9415C0.39667 40.7788 0.2 40.4551 0.2 40.1037V14.3224C0.2 13.9955 0.370222 13.6907 0.648396 13.5185C0.926105 13.3466 1.27634 13.3314 1.56738 13.4769C1.5674 13.4769 1.56742 13.4769 1.56743 13.4769L27.9028 26.6538C27.9029 26.6539 27.903 26.6539 27.9031 26.654C28.223 26.815 28.4252 27.1424 28.4252 27.4995V53.8537C28.4252 54.1849 28.2531 54.4913 27.9692 54.6635L27.9681 54.6642C27.8224 54.7538 27.6528 54.7995 27.4817 54.7995C27.3323 54.7995 27.1811 54.7635 27.0456 54.692ZM2.09167 39.4093V39.5305L2.1991 39.5866L26.2433 52.1403L26.5358 52.2931V51.963V28.2076V28.0841L26.4253 28.0288L2.38116 15.9975L2.09167 15.8527V16.1764V39.4093Z" fill="white" stroke="#111111" stroke-width="0.4"/>
                <path d="M53.4309 13.4776L53.4322 13.477C53.72 13.3302 54.0715 13.3451 54.349 13.5187L54.3504 13.5196C54.6305 13.6918 54.8005 13.9963 54.8005 14.3232V40.1044C54.8005 40.4559 54.6039 40.7795 54.2917 40.9423L54.2915 40.9423L27.9557 54.6923L27.9549 54.6927C27.8194 54.7642 27.6683 54.8002 27.5189 54.8002C27.3481 54.8002 27.1782 54.7547 27.0296 54.6646C26.7469 54.4919 26.573 54.185 26.573 53.8544V27.5002C26.573 27.1431 26.7753 26.8156 27.0953 26.6546C27.0953 26.6546 27.0954 26.6546 27.0954 26.6545L53.4309 13.4776ZM28.5752 28.0295L28.4647 28.0848V28.2084V51.9638V52.2938L28.7573 52.1411L52.8014 39.5873L52.9089 39.5312V39.41V16.1771V15.8534L52.6194 15.9983L28.5752 28.0295ZM53.8776 39.9044H53.8547V40.1044V40.3044H53.8776V40.1044V39.9044Z" fill="white" stroke="#111111" stroke-width="0.4"/>
                <path d="M27.6083 2.24785L27.5188 2.2031L27.4294 2.2478L1.5702 15.1682L1.57015 15.1682C1.43355 15.2365 1.29009 15.2686 1.14626 15.2686C0.797401 15.2686 0.464812 15.0773 0.298336 14.7464C0.064225 14.28 0.254345 13.7123 0.722564 13.4772C0.722596 13.4771 0.722628 13.4771 0.722661 13.4771L27.0944 0.300428C27.0945 0.300366 27.0946 0.300304 27.0947 0.300242C27.362 0.167727 27.6762 0.168072 27.9404 0.300175L54.2759 13.4771C54.7435 13.7119 54.9326 14.2795 54.7 14.7467C54.4656 15.2132 53.9007 15.4013 53.4302 15.1703C53.43 15.1702 53.4298 15.1701 53.4295 15.17L27.6083 2.24785Z" fill="white" stroke="#111111" stroke-width="0.4"/>
                <path d="M40.304 21.3332V21.2096L40.1935 21.1543L14.4901 8.29348L14.4898 8.29331C14.0232 8.06094 13.8337 7.49177 14.0683 7.02436C14.3027 6.55748 14.8702 6.36947 15.3383 6.6004C15.3384 6.60047 15.3386 6.60054 15.3387 6.60061L41.6724 19.7766C41.6726 19.7767 41.6728 19.7768 41.673 19.7769C41.9936 19.9404 42.1956 20.2682 42.1956 20.625V33.2292C42.1956 33.7513 41.7719 34.175 41.2498 34.175C40.7278 34.175 40.304 33.7513 40.304 33.2292V21.3332Z" fill="white" stroke="#111111" stroke-width="0.4"/>
            </g>
            <defs>
                <clipPath id="clip0_1052_821">
                    <rect width="55" height="55" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default BoxIcon;
