import React from 'react';

const ArrowIcon = ({width = '100%', height= '100%', fill = 'white'}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.41459 0.208477C1.17121 0.208001 0.935335 0.292767 0.747927 0.44806C0.642449 0.535506 0.55526 0.642904 0.491352 0.764099C0.427443 0.885294 0.388072 1.01791 0.375493 1.15434C0.362914 1.29077 0.377375 1.42835 0.418046 1.55919C0.458717 1.69002 0.5248 1.81155 0.61251 1.91681L5.27918 7.50014L0.779178 13.0939C0.692651 13.2004 0.628034 13.323 0.589043 13.4546C0.550051 13.5863 0.537454 13.7243 0.551974 13.8608C0.566494 13.9972 0.607844 14.1295 0.673651 14.25C0.739457 14.3704 0.82842 14.4767 0.935427 14.5626C1.0432 14.6575 1.16942 14.729 1.30615 14.7727C1.44288 14.8165 1.58718 14.8315 1.72998 14.8168C1.87279 14.8021 2.01102 14.7581 2.136 14.6875C2.26098 14.6169 2.37002 14.5212 2.45626 14.4064L7.48751 8.15639C7.64072 7.97 7.72448 7.73621 7.72448 7.49494C7.72448 7.25366 7.64072 7.01987 7.48751 6.83348L2.27918 0.583477C2.17468 0.457417 2.04194 0.357768 1.89172 0.292613C1.7415 0.227458 1.57804 0.198632 1.41459 0.208477Z" fill={fill}/>
        </svg>
    );
};

export default ArrowIcon;
