import React from 'react';

const ShowRoomsPlusIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.25C10.2155 0.25 10.4222 0.335603 10.5745 0.487976C10.7269 0.640349 10.8125 0.847012 10.8125 1.0625V9.1875H18.9375C19.153 9.1875 19.3597 9.2731 19.512 9.42548C19.6644 9.57785 19.75 9.78451 19.75 10C19.75 10.2155 19.6644 10.4222 19.512 10.5745C19.3597 10.7269 19.153 10.8125 18.9375 10.8125H10.8125V18.9375C10.8125 19.153 10.7269 19.3597 10.5745 19.512C10.4222 19.6644 10.2155 19.75 10 19.75C9.78451 19.75 9.57785 19.6644 9.42548 19.512C9.2731 19.3597 9.1875 19.153 9.1875 18.9375V10.8125H1.0625C0.847012 10.8125 0.640349 10.7269 0.487976 10.5745C0.335603 10.4222 0.25 10.2155 0.25 10C0.25 9.78451 0.335603 9.57785 0.487976 9.42548C0.640349 9.2731 0.847012 9.1875 1.0625 9.1875H9.1875V1.0625C9.1875 0.847012 9.2731 0.640349 9.42548 0.487976C9.57785 0.335603 9.78451 0.25 10 0.25Z" fill="#BC8762"/>
        </svg>
    );
};

export default ShowRoomsPlusIcon;
