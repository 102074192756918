import React from 'react';

const SuccessForm = ({stroke = '#0C0C0C'}) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 143 143" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M58.3737 87.881L58.7266 88.2325L59.0795 87.881L98.5972 48.517L98.5993 48.5149C98.9181 48.1935 99.2978 47.9383 99.7165 47.764C100.135 47.5898 100.585 47.5 101.038 47.5C101.492 47.5 101.942 47.5898 102.36 47.764C102.779 47.9383 103.159 48.1935 103.478 48.5149L103.482 48.5192C103.805 48.8367 104.061 49.2147 104.235 49.6313C104.41 50.0478 104.5 50.4947 104.5 50.9461C104.5 51.3975 104.41 51.8444 104.235 52.2609C104.061 52.6775 103.805 53.0555 103.482 53.373L103.48 53.375L61.1681 95.4826L61.1679 95.4827C59.8063 96.8391 57.607 96.8391 56.2454 95.4827L39.5202 78.8226L39.518 78.8205C39.1954 78.503 38.9394 78.125 38.7647 77.7084C38.5899 77.2918 38.5 76.8449 38.5 76.3935C38.5 75.9422 38.5899 75.4953 38.7647 75.0787L38.3036 74.8853L38.7647 75.0787C38.9394 74.6621 39.1954 74.2841 39.518 73.9666L39.5181 73.9666L39.5223 73.9623C39.8411 73.6409 40.2208 73.3857 40.6395 73.2114C41.0582 73.0372 41.5076 72.9474 41.9615 72.9474C42.4154 72.9474 42.8648 73.0372 43.2835 73.2114C43.7022 73.3857 44.082 73.6409 44.4007 73.9623L44.4028 73.9645L58.3737 87.881Z" fill="#BC8762" stroke={stroke}/>
            <circle cx="71.5" cy="71.5" r="69" stroke="#BC8762" stroke-width="5"/>
        </svg>
    );
};

export default SuccessForm;
