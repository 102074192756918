import React from 'react';

const SmartphoneIcon = () => {
    return (
        <svg key='1' width="100%" height="100%" viewBox="0 0 52 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 41C17.3261 41 18.5979 40.4732 19.5355 39.5355C20.4732 38.5979 21 37.3261 21 36C21 34.6739 20.4732 33.4021 19.5355 32.4645C18.5979 31.5268 17.3261 31 16 31C14.6739 31 13.4021 31.5268 12.4645 32.4645C11.5268 33.4021 11 34.6739 11 36C11 37.3261 11.5268 38.5979 12.4645 39.5355C13.4021 40.4732 14.6739 41 16 41ZM11 1C8.34784 1 5.8043 2.05357 3.92893 3.92893C2.05357 5.8043 1 8.34784 1 11V61C1 63.6522 2.05357 66.1957 3.92893 68.0711C5.8043 69.9464 8.34784 71 11 71H41C43.6522 71 46.1957 69.9464 48.0711 68.0711C49.9464 66.1957 51 63.6522 51 61V11C51 8.34784 49.9464 5.8043 48.0711 3.92893C46.1957 2.05357 43.6522 1 41 1H11ZM6 11C6 9.67392 6.52679 8.40215 7.46447 7.46447C8.40215 6.52678 9.67392 6 11 6H41C42.3261 6 43.5979 6.52678 44.5355 7.46447C45.4732 8.40215 46 9.67392 46 11V61C46 62.3261 45.4732 63.5979 44.5355 64.5355C43.5979 65.4732 42.3261 66 41 66H11C9.67392 66 8.40215 65.4732 7.46447 64.5355C6.52679 63.5979 6 62.3261 6 61V11Z" fill="#BC8762" stroke="#282828"/>
        </svg>
    );
};

export default SmartphoneIcon;
