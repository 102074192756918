export const samara = {
    city: 'Самаре',
    citySub: [
        [<>МЕГА Самара, остров ERGONOVA <br/>Московское шоссе, 24-й километр, 5</>, '1 этаж, рядом с М.Видео', 'с 10 до 22, ежедневно'],
        [<>МЕГА Самара, Салон ERGONOVA <br/>Московское шоссе, 24-й километр, 5</>, <>1 этаж, по стороне гипермаркета Ашан,<br/> рядом с Аптекой</>, 'с 10 до 22, ежедневно'],
        [<>ТРК КОСМОПОРТ, остров ERGONOVA <br/>ул. Дыбенко, 30</>, 'с 10:00 до 23:00, ежедневно'],

    ]
}

export const ufa = {
    city: 'Уфе',
    citySub: [
        [<>МЕГА Уфа, Салон ERGONOVA <br/> ул. Рубежная, 174</>, 'с 10 до 22, ежедневно'],
        [<>МЕГА Уфа, остров ERGONOVA <br/> ул. Рубежная, 174</>, 'с 10 до 22, ежедневно'],
    ]
}

export const tymen = {
    city: 'Тюмени',
    citySub: [
        [<>ТК «ОРИОН» Салон ERGONOVA <br/> ул. Федюнинского, 43, 1
            уровень</>, 'рядом с магазином «Амега»', 'ежедневно, 10:00–21:00'],
        [<>ТРЦ «Премьер» <br/>ул. 50 лет ВЛКСМ, д. 63</>, '(вход со стороны АЗС)', 'ежедневно, 10:00–21:00'],
    ]
}

export const sanktPeterburg = {
    city: <>Санкт-<br/>Петербурге</>,
    citySub: [
        [<>ТРЦ Мега Парнас, Салон ERGONOVA <br/>Проспект Энгельса / КАД внешнее кольцо 117 километр, 1, 1
            этаж</>, <>Вход Икея, прямо по коридору <br/>до конца, справа</>, 'ежедневно, 10:00–22:00'],
        [<>ТРЦ «Премьер» <br/>ул. 50 лет ВЛКСМ, д. 63</>, '(вход со стороны АЗС)', 'ежедневно, 10:00–21:00'],
    ]
}

export const chelyabinsk = {
    city: 'Челябинск',
    citySub: [
        [<>ТРК Родник, Салон ERGONOVA <br/>Ул. Труда, 203</>, <>(–2 этаж), напротив Castorama</>, <>c 10 до 22, ежедневно</>],
        [<>ТРЦ Алмаз, Салон ERGONOVA <br/>Копейское шоссе, 64</>, <>2 этаж, рядом с Leroy Merlin</>, <>с 10 до 22, ежедневно</>],
        [<>ТРК Космос, Салон ERGONOVA <br/>Ул. Чичерина, 28</>, <>с 10 до 22, ежедневно</>],
    ]
}

export const ekaterenburg = {
    city: 'Екатеренбурге',
    citySub: [
        [<>РЦ РАДУГА ПАРК, Салон ERGONOVA <br/>Ул. Репина, 94</>, <>напротив Yota</>, <>с 10 до 22, ежедневно</>],
        [<>ТЦ Дирижабль <br/>Ак. Шварца</>, <>ежедневно, 10:00–22:00</>],
    ]
}

export const surgut = {
    city: 'Сургуте',
    citySub: [
        [<>ТРЦ АУРА, Салон ERGONOVA <br/>Нефтеюганское шоссе, 1</>, <>Центральный вход, 2-ой этаж, по левой<br/> стороне от Спортмастера</>, <>ежедневно, 10:00–22:00</>],
    ]
}

export const magnutigorsk = {
    city: 'Магнитогорске',
    citySub: [
        [<>ТРК Гостиный Двор, Салон ERGONOVA <br/>г. Магнитогорск, проспект Карла Маркса, 153</>, <>1 этаж</>, <>с 10 до 21, ежедневно</>],
    ]
}

export const orenburg = {
    city: 'Оренбурге',
    citySub: [
        [<>Молл Армада, остров ERGONOVA <br/>Шарлыкское шоссе, 1</>, <>Молл Армада, галерея 1, 1 этаж</>, <>с 10 до 22, ежедневно</>],
        [<>ТРЦ ARMADA-2, Салон ERGONOVA <br/>Нежинское шоссе, 2А</>, <>1 этаж, рядом с магазином «Лента»</>, <>с 10 до 22, ежедневно</>],
        [<>Молл Армада, Салон ERGONOVA <br/>Шарлыкское шоссе, 1</>, <>Молл Армада, галерея Лувр, 1 этаж</>, <>с 10 до 22, ежедневно</>],
    ]
}
