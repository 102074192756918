import React from 'react';

const MinusIcon = ({fill = '#C5C3C6'}) => {
    return (
      <svg width="15" height="15" viewBox="0 0 15 15"  opacity='0.5' fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.867407 0.870874C1.01392 0.724361 1.21263 0.642051 1.41983 0.642051C1.62704 0.642051 1.82575 0.724361 1.97226 0.870874L7.49653 6.39515L13.0208 0.870874C13.1673 0.724361 13.366 0.642051 13.5732 0.642051C13.7804 0.642051 13.9791 0.724361 14.1257 0.870874C14.2722 1.01739 14.3545 1.2161 14.3545 1.4233C14.3545 1.6305 14.2722 1.82922 14.1257 1.97573L8.60139 7.5L14.1257 13.0243C14.2722 13.1708 14.3545 13.3695 14.3545 13.5767C14.3545 13.7839 14.2722 13.9826 14.1257 14.1291C13.9791 14.2756 13.7804 14.3579 13.5732 14.3579C13.366 14.3579 13.1673 14.2756 13.0208 14.1291L7.49653 8.60485L1.97226 14.1291C1.82575 14.2756 1.62704 14.3579 1.41983 14.3579C1.21263 14.3579 1.01392 14.2756 0.867407 14.1291C0.720895 13.9826 0.638585 13.7839 0.638584 13.5767C0.638584 13.3695 0.720894 13.1708 0.867407 13.0243L6.39168 7.5L0.867407 1.97573C0.720894 1.82922 0.638584 1.6305 0.638584 1.4233C0.638585 1.2161 0.720895 1.01739 0.867407 0.870874Z" fill="white"/>
      </svg>
    );
};

export default MinusIcon;
