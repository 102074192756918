import React from 'react';

const DeliveryAutoIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.5734 20.5301H30.2558V18.3673H32.4106V12.0606L30.5557 6.48795H22.6861V18.3673H23.7675V20.5301H20.5312V4.3252H32.1107L34.5734 11.7133V20.5301Z" fill="#BC8762"/>
            <path d="M27.013 23.7741C24.6292 23.7741 22.6875 21.8323 22.6875 19.4486C22.6875 17.0648 24.6292 15.123 27.013 15.123C29.3968 15.123 31.3385 17.0648 31.3385 19.4486C31.3306 21.8323 29.3968 23.7741 27.013 23.7741ZM27.013 17.2858C25.8211 17.2858 24.8503 18.2567 24.8503 19.4486C24.8503 20.6404 25.8211 21.6113 27.013 21.6113C28.2049 21.6113 29.1758 20.6404 29.1758 19.4486C29.1758 18.2567 28.2049 17.2858 27.013 17.2858Z" fill="#BC8762"/>
            <path d="M6.48169 20.5305H2.16406V12.9688H4.31893V18.3678H6.48169V20.5305Z" fill="#BC8762"/>
            <path d="M22.6853 20.5304H12.9608V18.3677H20.5304V2.16276H0V0H22.6853V20.5304Z" fill="#BC8762"/>
            <path d="M9.72395 23.7741C7.34018 23.7741 5.39844 21.8323 5.39844 19.4486C5.39844 17.0648 7.34018 15.123 9.72395 15.123C12.1077 15.123 14.0495 17.0648 14.0495 19.4486C14.0416 21.8323 12.1077 23.7741 9.72395 23.7741ZM9.72395 17.2858C8.53207 17.2858 7.56119 18.2567 7.56119 19.4486C7.56119 20.6404 8.53207 21.6113 9.72395 21.6113C10.9158 21.6113 11.8867 20.6404 11.8867 19.4486C11.8867 18.2567 10.9158 17.2858 9.72395 17.2858Z" fill="#BC8762"/>
            <path d="M10.8066 4.3252H1.08203V6.48795H10.8066V4.3252Z" fill="#BC8762"/>
            <path d="M8.64444 8.64258H2.16406V10.8053H8.64444V8.64258Z" fill="#BC8762"/>
        </svg>
    );
};

export default DeliveryAutoIcon;
