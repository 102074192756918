import React from 'react';

const OffButtonIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.22209 8.22209C18.9199 -2.47584 36.0837 -2.47222 46.7779 8.22209C57.4758 18.9199 57.4722 36.0838 46.7779 46.7779C36.0801 57.4758 18.9163 57.4722 8.22209 46.7779C-2.47584 36.0801 -2.47222 18.9163 8.22209 8.22209ZM3.02744 27.5C3.02744 40.9944 14.0056 51.9726 27.5 51.9726C40.9944 51.9726 51.9726 40.9944 51.9726 27.5C51.9726 14.0056 40.9944 3.02744 27.5 3.02744C14.0056 3.02744 3.02744 14.0056 3.02744 27.5Z" fill="white" stroke="#111111" stroke-width="0.4"/>
            <path d="M33.2853 18.85L33.2854 18.85C33.8026 18.2671 34.6944 18.2139 35.2773 18.7312C37.7837 20.9556 39.2212 24.1519 39.2211 27.5008C39.2211 33.9639 33.9627 39.2223 27.4996 39.2223C21.0365 39.2223 15.7781 33.9639 15.7781 27.5008C15.7781 24.1189 17.2394 20.9016 19.7872 18.6739C20.374 18.1609 21.2653 18.2206 21.7783 18.8072L21.7783 18.8072C22.2913 19.3939 22.2315 20.2853 21.6449 20.7983C19.7101 22.4901 18.6003 24.933 18.6003 27.5008C18.6003 32.4082 22.5923 36.4003 27.4997 36.4003C32.4071 36.4003 36.3991 32.4082 36.3991 27.5008C36.3991 24.9581 35.3075 22.5312 33.4042 20.842L33.4042 20.842C32.8212 20.3247 32.7681 19.4329 33.2853 18.85Z" fill="white" stroke="#111111" stroke-width="0.4"/>
            <path d="M28.9107 23.205C28.9107 23.9843 28.2789 24.616 27.4997 24.616C26.7204 24.616 26.0887 23.9844 26.0887 23.205V14.613C26.0887 13.8337 26.7204 13.202 27.4997 13.202C28.2789 13.202 28.9107 13.8337 28.9107 14.613V23.205Z" fill="white" stroke="#111111" stroke-width="0.4"/>
        </svg>

    );
};

export default OffButtonIcon;
