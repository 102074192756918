import React from 'react';

const DeliveryIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1049_918)">
        <path d="M30.21 23.7812C27.4298 23.7812 25.168 26.043 25.168 28.8233C25.168 31.6035 27.4298 33.8653 30.21 33.8653C32.9907 33.8653 35.252 31.6035 35.252 28.8233C35.252 26.043 32.9902 23.7812 30.21 23.7812ZM30.21 31.3443C28.8197 31.3443 27.689 30.2136 27.689 28.8233C27.689 27.433 28.8197 26.3023 30.21 26.3023C31.6003 26.3023 32.731 27.433 32.731 28.8233C32.731 30.2137 31.6003 31.3443 30.21 31.3443Z" fill="#BC8762"/>
        <path d="M12.9834 23.7812C10.2032 23.7812 7.94141 26.043 7.94141 28.8233C7.94141 31.6035 10.2032 33.8653 12.9834 33.8653C15.7637 33.8653 18.0255 31.6035 18.0255 28.8233C18.0255 26.043 15.7637 23.7812 12.9834 23.7812ZM12.9834 31.3443C11.5931 31.3443 10.4624 30.2136 10.4624 28.8233C10.4624 27.433 11.5931 26.3023 12.9834 26.3023C14.3734 26.3023 15.5045 27.433 15.5045 28.8233C15.5045 30.2137 14.3738 31.3443 12.9834 31.3443Z" fill="#BC8762"/>
        <path d="M33.605 9.34942C33.3907 8.92379 32.955 8.65527 32.4785 8.65527H25.8398V11.1763H31.7012L35.1335 18.0032L37.3865 16.8704L33.605 9.34942Z" fill="#BC8762"/>
        <path d="M26.4284 27.605H16.8906V30.126H26.4284V27.605Z" fill="#BC8762"/>
        <path d="M9.20242 27.605H4.83273C4.13648 27.605 3.57227 28.1693 3.57227 28.8654C3.57227 29.5617 4.13656 30.1259 4.83273 30.1259H9.2025C9.89875 30.1259 10.463 29.5616 10.463 28.8654C10.463 28.1692 9.89867 27.605 9.20242 27.605Z" fill="#BC8762"/>
        <path d="M39.7361 19.899L37.2567 16.7057C37.0185 16.3981 36.6509 16.2183 36.2613 16.2183H27.1016V7.39475C27.1016 6.6985 26.5373 6.13428 25.8412 6.13428H4.83273C4.13648 6.13428 3.57227 6.69857 3.57227 7.39475C3.57227 8.09092 4.13656 8.65522 4.83273 8.65522H24.5806V17.4787C24.5806 18.175 25.1449 18.7392 25.8411 18.7392H35.6441L37.4798 21.1039V27.6047H33.9923C33.2961 27.6047 32.7319 28.169 32.7319 28.8652C32.7319 29.5615 33.2962 30.1257 33.9923 30.1257H38.7402C39.4365 30.1257 40.0007 29.5614 40.0008 28.8652V20.6721C40.0008 20.3922 39.9075 20.12 39.7361 19.899Z" fill="#BC8762"/>
        <path d="M9.11742 21.2188H3.31906C2.62281 21.2188 2.05859 21.783 2.05859 22.4792C2.05859 23.1755 2.62289 23.7397 3.31906 23.7397H9.11734C9.81359 23.7397 10.3778 23.1754 10.3778 22.4792C10.3779 21.783 9.81359 21.2188 9.11742 21.2188Z" fill="#BC8762"/>
        <path d="M12.0168 16.2603H1.26047C0.564297 16.2603 0 16.8245 0 17.5208C0 18.217 0.564297 18.7813 1.26047 18.7813H12.0168C12.713 18.7813 13.2773 18.217 13.2773 17.5208C13.2773 16.8246 12.713 16.2603 12.0168 16.2603Z" fill="#BC8762"/>
        <path d="M14.0754 11.3027H3.31906C2.62281 11.3027 2.05859 11.867 2.05859 12.5632C2.05859 13.2595 2.62289 13.8237 3.31906 13.8237H14.0754C14.7716 13.8237 15.3359 13.2594 15.3359 12.5632C15.3359 11.867 14.7716 11.3027 14.0754 11.3027Z" fill="#BC8762"/>
      </g>
      <defs>
        <clipPath id="clip0_1049_918">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default DeliveryIcon;
