import React from 'react';

const TelegramIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35 0H5C2.23858 0 0 2.23858 0 5V35C0 37.7614 2.23858 40 5 40H35C37.7614 40 40 37.7614 40 35V5C40 2.23858 37.7614 0 35 0Z" fill="#039BE5"/>
            <path d="M31.4948 8.83056L4.74627 19.2542C3.87988 19.5918 3.93237 20.8351 4.82421 21.0984L9.06857 22.3518C10.6644 22.823 11.9212 24.0572 12.4213 25.6442L14.158 31.1547C14.4229 31.9955 15.4884 32.2503 16.1052 31.6206L16.5848 31.1308C18.326 29.353 21.1096 29.1261 23.1156 30.5986L26.5147 33.0936C27.3586 33.713 28.5603 33.2529 28.7746 32.2283L33.3414 10.3944C33.565 9.32489 32.5128 8.43384 31.4948 8.83056ZM28.1412 13.6761L15.7167 24.6631C15.5928 24.7726 15.514 24.9242 15.4956 25.0885L15.0172 29.3408C15.0016 29.4795 14.8076 29.4978 14.7661 29.3646L12.798 23.0229C12.7079 22.7325 12.8254 22.418 13.0837 22.2578L27.7558 13.158C28.0932 12.9486 28.4387 13.4131 28.1412 13.6761Z" fill="white"/>
        </svg>
    );
};

export default TelegramIcon;
