import React from 'react';

const LogoIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 88 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.076 26.1015C41.076 33.7465 41.076 41.3646 41.076 48.9961C41.076 49.7809 41.0351 50.3086 40.4485 51.0122C39.9711 51.5805 39.1663 52 38.225 52C37.4612 52 36.4654 51.6617 35.8652 50.8499C35.5651 50.4439 35.3605 49.9433 35.3605 49.2803C35.3605 47.3588 35.3605 31.974 35.3605 31.6222C35.3605 31.4327 35.3741 30.6344 35.3332 30.3773C35.2923 30.0932 35.1422 29.7819 34.9649 29.5654C34.8012 29.3625 34.5011 29.1324 34.2146 29.0377C33.9691 28.943 33.7372 28.9295 33.478 28.9295C33.1506 28.9295 31.9093 28.9295 30.7771 28.9295C30.2724 28.9295 29.7813 29.1595 29.4403 29.579C28.9493 30.1879 29.072 30.8238 29.0584 31.6898C29.0175 37.1293 29.0584 43.2183 29.0584 48.5766C29.0584 49.6862 29.0857 50.2545 28.3627 51.0664C27.8307 51.6617 26.9713 51.9865 26.1256 51.9865C25.4163 51.9865 24.6387 51.6753 24.1749 51.2017C23.3838 50.4169 23.3429 49.7944 23.3429 48.6578C23.3565 35.0185 23.3292 21.3791 23.3565 7.73978C23.3565 6.26489 23.3429 4.53291 23.3429 3.27452C23.3429 2.69269 23.2064 1.88082 23.9021 1.05542C24.4205 0.432993 25.3208 0 26.1938 0C26.9577 0 27.8443 0.338277 28.4309 1.0013C28.922 1.56961 29.072 2.20557 29.072 3.2204C29.0584 8.93052 29.072 15.8043 29.072 21.528C29.072 22.0286 29.2221 22.5834 29.795 23.0029C30.3679 23.4223 30.9135 23.3276 31.7456 23.3276C32.1412 23.3276 33.1234 23.3276 33.5462 23.3276C34.2283 23.3276 34.6648 23.0705 34.9512 22.7322C35.2513 22.3804 35.3741 22.0286 35.3741 21.4738C35.3741 15.6014 35.3741 8.70049 35.3741 3.0445C35.3741 2.07026 35.4832 1.48842 36.2335 0.757738C36.6973 0.311212 37.4884 0 38.2387 0C38.8252 0 39.6164 0.202964 40.1893 0.703615C40.8987 1.32604 41.1033 2.0026 41.0896 2.7874C40.9941 9.59354 41.0623 18.3482 41.076 26.1015C41.0623 26.1015 41.076 26.1015 41.076 26.1015Z" fill="white"/>
      <path d="M46.8871 28.4966C46.8871 22.0558 46.8871 14.2619 46.8871 7.91583C46.8871 5.20962 48.3739 2.94992 50.3246 1.62388C52.0979 0.433139 53.7075 0.0136719 55.7127 0.0136719C58.3318 0.0136719 60.828 0.987912 62.3285 2.34102C64.3883 4.20831 64.6884 6.45447 64.6884 7.79405C64.6884 11.8128 64.6884 15.588 64.6884 19.6067C64.6884 20.5539 64.5929 21.1492 63.8018 21.907C63.3243 22.3535 62.6559 22.6241 61.7147 22.6377C60.8144 22.6377 60.1869 22.2859 59.6822 21.7581C59.1366 21.1763 58.932 20.7568 58.932 19.9044C58.932 16.4269 58.932 12.4352 58.932 8.80888C58.932 6.48153 57.4315 5.49377 55.8082 5.49377C54.1713 5.49377 52.6572 6.71156 52.6572 8.74123C52.6572 10.7168 52.6162 14.6273 52.6162 17.5635C52.6162 26.1693 52.6572 35.1269 52.6572 43.7326C52.6572 45.6135 54.2122 46.8313 55.7264 46.8448C57.3769 46.8583 58.9592 45.6946 58.9592 43.6515C58.9592 40.4852 58.932 37.3054 58.9592 34.1391C58.9592 33.4625 58.9865 33.4219 58.9592 32.6507C58.9456 32.0824 58.9592 31.5817 59.4094 30.8916C59.8323 30.2286 60.7871 29.6603 61.7011 29.6603C63.0651 29.6603 63.7063 30.2286 64.1428 30.7563C64.7157 31.4464 64.6884 32.2312 64.6884 32.8266C64.6884 37.2377 64.6884 41.6218 64.6884 44.0574C64.6884 46.8719 62.9697 48.9827 61.7556 49.9299C60.0642 51.2424 57.6224 51.9866 55.6172 51.9866C53.803 51.9866 51.334 51.3506 49.6562 49.8352C48.3739 48.6715 46.8598 46.8448 46.8871 43.8409C46.9416 38.4149 46.8871 32.9484 46.8871 28.4966Z" fill="white"/>
      <path d="M17.4904 38.5501C17.4904 43.5701 13.3845 46.6687 9.11489 46.8311C7.32793 46.8988 6.01841 46.4793 5.00898 46.0734C3.18111 45.3021 1.44872 43.7596 0.589342 41.7299C0.234679 40.9045 0.0164247 39.9032 0.00278387 39.0913C-0.010857 38.3877 0.0300652 33.5977 0.0300652 13.707C0.0300652 9.53943 2.55363 7.25267 5.25452 6.14312C8.67838 4.72236 13.6027 5.62894 16.0445 9.0929C17.013 10.446 17.4767 12.0021 17.4767 13.6529C17.4767 15.5878 17.4631 18.1723 17.4767 20.3102C17.504 22.4616 17.4904 34.599 17.4904 38.5501ZM11.9113 15.5743C11.9113 14.6812 12.0068 13.707 11.8294 12.9222C11.5293 11.6097 10.2062 10.446 8.67838 10.5001C6.79594 10.5678 5.67739 12.0562 5.59554 13.3146C5.54098 13.9641 5.60918 14.8166 5.60918 15.5472C5.5819 23.1246 5.56826 30.4991 5.56826 38.0765C5.56826 40.2956 7.25973 41.3781 8.69202 41.3781C10.479 41.3781 11.9249 40.1062 11.9249 38.0224C11.8976 34.0578 11.9249 19.0924 11.9113 15.5743Z" fill="white"/>
      <path d="M88.0002 38.5501C88.0002 43.5701 83.8943 46.6687 79.6247 46.8311C77.8377 46.8988 76.5282 46.4793 75.5187 46.0734C73.6909 45.3021 71.9585 43.7596 71.0991 41.7299C70.7444 40.9045 70.5262 39.9032 70.5125 39.0913C70.4989 38.3877 70.5398 33.5977 70.5398 13.707C70.5398 9.53943 73.0634 7.25267 75.7643 6.14312C79.1882 4.72236 84.1125 5.62894 86.5542 9.0929C87.5227 10.446 87.9865 12.0021 87.9865 13.6529C87.9865 15.5878 87.9729 18.1723 87.9865 20.3102C88.0002 22.4616 88.0002 34.599 88.0002 38.5501ZM82.4074 15.5743C82.4074 14.6812 82.5029 13.707 82.3255 12.9222C82.0254 11.6097 80.7023 10.446 79.1745 10.5001C77.2921 10.5678 76.1735 12.0562 76.0917 13.3146C76.0371 13.9641 76.1053 14.8166 76.1053 15.5472C76.078 23.1246 76.0644 30.4991 76.0644 38.0765C76.0644 40.2956 77.7559 41.3781 79.1881 41.3781C80.9751 41.3781 82.421 40.1062 82.421 38.0224C82.4074 34.0578 82.4347 19.0924 82.4074 15.5743Z" fill="white"/>
    </svg>

  );
};

export default LogoIcon;
