import React from 'react';

const FormArrowIcon = () => {
    return (
        <svg width="7.8" height="12.48" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.84508 6.48366L1.87943 0.596414C1.58498 0.347549 1.13098 0.347549 0.83653 0.596414C0.681789 0.727199 0.589453 0.912705 0.589453 1.1135C0.589453 1.31429 0.681789 1.4998 0.83653 1.63058L7.18883 6.99944L0.83653 12.3683C0.681789 12.4991 0.589453 12.6846 0.589453 12.8854C0.589453 13.0862 0.681789 13.2717 0.83653 13.4025C0.985084 13.528 1.17519 13.5898 1.35643 13.5898C1.53087 13.5898 1.72437 13.5345 1.87844 13.4007L8.84089 7.51614C9.17146 7.24065 9.17175 6.75976 8.84508 6.48366Z" fill="white" stroke="white" stroke-width="0.7"/>
        </svg>
    );
};

export default FormArrowIcon;
